<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
       <!-- <div  v-if="!loading&&showdiv=='0'" class="host-body"> -->
      <div  v-if="!loading" class="host-body">
        <div class="d-flex jc-center" style="margin-top: 25px;">
          <dv-decoration-10 style="width:23.3%;height:.0625rem;" />
          <div class="d-flex jc-center"  style="width:53.3%;" >
            <dv-decoration-8 :color="['#568aea', '#000000']" style="width:1.5rem;height:.625rem;" />
            <div class="title" style="width: 10rem;">
              <span class="title-text" style="font-size:44px;position: initial;font-weight: 900;color: cyan;">旭升帽业产量监控中心</span>
              <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width:3.125rem;height:.1rem;"
              />
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width:1.5rem;height:.625rem;"
            />
          </div>
          <dv-decoration-10 style="width:23.3%;height:.0625rem; transform: rotateY(180deg);" />
        </div>

        <!-- 第二行 -->
        <!-- <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
           
               <div class="react-left bg-color-blue mr-3">
             <span class="text fw-b" style="font-size: 30px;color: rgb(255, 193, 7);">数据是一面镜子</span>
           
            </div>
           
          </div>
          <div style="width: 40%" class="d-flex">
            <div
              class="react-right ml-4"
              style="width: 6.25rem; text-align: left;background-color: #0f1325;"
            >
              <span class="react-before"></span>
              <span class="text fw-b"  style="font-size:24px;" >订单分析</span>
               </div>
            <div
              class="react-left mr-4"
              style="width: 6.25rem; background-color: #0f1325; text-align: right;"
            >
              <span class="react-after"></span>
              <span class="text">{{dateYear}} {{dateWeek}} {{dateDay}}</span>
            </div>
          </div>
        </div> -->

        <div class="body-box">
           
          <!-- 第三行数据 -->
          <div class="center-box">
              <dv-border-box-13>
                 <upcenter/>
              </dv-border-box-13>
          </div>
          <div class="bototm-box">
            <dv-border-box-13>
              <bottomcenter/>
            </dv-border-box-13>
          </div>
        </div>
      </div>
      
    </dv-full-screen-container>
  </div>
</template>

<script>
import {} from '../libs/axios';
import { formatTime } from '../utils/index.js'
// import centerLeft from "./mainview/centerLeft";
// import upcenter from "./ordermain/upcenter";
import upcenter from "./ordermain/ordermainshow";
import bottomcenter from "./ordermain/bottomcenter";

 
//  alert(getStore("id"));
export default {
  data () {
    return {
       selectList: [], // 多选数据
      loading: true,
      showdiv:"0",
      workName:"",
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        // 添加或编辑表单对象初始化数据
        lineGuids: "",
        workShopName:"",
    };
  },
  components: {
    upcenter,
    bottomcenter
  },
  mounted () {
    this.init();
    this.timeFn();
    this.cancelLoading();
    // this.changediv1();
  },
  methods: {
     init() {
    },
    timeFn () {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000)
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  
     changediv1 () {
      setInterval(() => {
       if(this.$store.state.divshow=="0")
       {
        this.showdiv = "1";
        this.$store.commit('setDivshow', "1");
       }
       else
       {
          this.showdiv = "0";
          this.$store.commit('setDivshow', "0");
       }
       
      }, 10000);
    },
  }
};
</script>

<style lang="scss">
@import './ordermain/index.scss';
</style>